import React from 'react'
import Layout from '../components/layout'
import short01 from '../assets/images/movie/short01.jpg'
import short02 from '../assets/images/movie/short02.png'
import Helmet from 'react-helmet'

const Shortfilm = () => {
  const siteTitle = 'Onodera Ryo | short film'

  return(
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>
      <div id="main">
        <h2 class="name2">Short Film</h2>
        <p class="own_desc">トウメイの壁 / RABBIT / 2017 / 日本 23分<br></br>Director: 小野寺亮 Cast : 木竜麻生 / 森優作 / 井端珠里 / 潮みか</p>
        <img src={short01} alt="short" class="short_img" />
        <img src={short02} alt="short" class="short_img short2" />
      </div>
    </Layout>
  )
}

export default Shortfilm